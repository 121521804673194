.customSidebar {
  display: flex;
}

.rightSideContent {
  margin-left: 16vw;
  padding: 2px;
  margin-top: 60px;
  /* overflow-x: hidden; */
}

.rightSideContentCollasped {
  margin-left: 6.5vw;
  padding: 2px;
  margin-top: 60px;
  /* overflow-x: hidden; */
}