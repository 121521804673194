.branchTable2 {
    width: 70%;
    display: inline-block;
    height: 40vh;
    overflow: hidden;
}

/* branchTable2 on mouse hover */
.branchTable2:hover {
    overflow-y: scroll;
    overflow-x: scroll;
}

/* branchTable2 on mouse hover and scroll bar */
.branchTable2:hover::-webkit-scrollbar {
    width: 4px;
}

/* branchTable2 scrollbar track area */
.branchTable2:hover::-webkit-scrollbar-track {
    border-radius: 2px;
}

/* branchTable2 scrollbar color*/
.branchTable2:hover::-webkit-scrollbar-thumb {
    background: #A8A8A8;
    border-radius: 2px;
}

/* branchTable2 scrollbar on hover */
.branchTable2:hover::-webkit-scrollbar-thumb:hover {
    background: #808183;
}