.modal-90w {
    width: 90%;
    max-width: none !important;
  }
  .ant-picker-calendar-header {
    margin-right: 0.5rem;
  }
  .ant-picker-date-panel {
    z-index: 0;
  }
  .site-calendar-demo-card {
    width: 320px;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    z-index: 0;
  }
  .full_display{
    width: auto;
  }
  .row_update{
    display: inline-flex;
  }
  .swal2-popup{
    width:auto !important;
  }