  
  body {
    background: '#C62828' ;
    background-color: '#cfe2f3'
  }
  
  .loading-spinner-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    border-radius: 50px;
    padding: 40px;
    height: 230px;
    /* width:850px; */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    background-color: #9da0a1;;
  }
  
  .spinner {
    border-radius: 50%;
    box-sizing: border-box;
    border: 5px solid var(--spinner-base-color);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .inner {
    border-top: 5px solid var(--spinner-highlight-color);
    border-bottom: 5px solid var(--spinner-highlight-color);
    width: 70px;
    height: 70px;
    margin: -35px;
    animation: inner-spin 1s linear infinite;
  }
  
  .outer {
    border-right: 5px solid var(--spinner-highlight-color);
    border-left: 5px solid var(--spinner-highlight-color);
    width: 90px;
    height: 90px;
    margin: -45px;
    animation: outer-spin 1s linear infinite;
  }
  
  .eye {
    width: 50px;
    height: 50px;
    border: none;
    background-color: var(--spinner-highlight-color);
    animation: eye-flash 1s infinite;
  }
  
  .loading-text {
    margin-top: 60px; /* Adjust the margin to control the space between the spinner and text */
    text-align: center;
  }
  
  
  @keyframes inner-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    
  }
  
  @keyframes outer-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-180deg); }
  }
  
  @keyframes eye-flash {
    0% { background-color: var(--spinner-highlight-color); }
    50% { background-color: var(--spinner-base-color); }
    100% { background-color: var(--spinner-highlight-color); }
  }

  @keyframes moveLeftToRight {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  } 
  
  

  select {
    margin: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    height: 36px;
    width: 230px ;
    transition: all 100ms;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    font-weight: 600;
    box-sizing: border-box;
  }

  .custom-datepicker {
    z-index: 10000;
  }
.ant-picker-dropdown{
  z-index: 99999;
}
  
  
  