.modal-width {
    width: "100%";
  }
  .table-no-gutter tbody,
  .table-no-gutter tr,
  .table-no-gutter td {
    padding: 0;
    margin: 0;
  }
  .removeScroll {
    overflow-y: "auto";
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .removeScroll::-webkit-scrollbar {
    width: "0.0em";
    height: "0.0em";
    display: none;
  }

  