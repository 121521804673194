.branchTable {
    width: 100%;
    display: inline-block;
    height: 36vh;
    overflow: hidden;
}
/* branchTable on mouse hover */
.branchTable:hover {
    overflow-y: scroll;
    overflow-x: hidden;
}
/* branchTable on mouse hover and scroll bar */
.branchTable:hover::-webkit-scrollbar {
    width: 4px;
}
/* branchTable scrollbar track area */
.branchTable:hover::-webkit-scrollbar-track {
    border-radius: 2px;
}
/* branchTable scrollbar color*/
.branchTable:hover::-webkit-scrollbar-thumb {
    background: #A8A8A8;
    border-radius: 2px;
}
/* branchTable scrollbar on hover */
.branchTable:hover::-webkit-scrollbar-thumb:hover {
    background: #808183;
}