/* src/App.css */
/* Style the chat container */
.chat-container {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
}
/* Style the chat header */
.chat-header {
    text-align: center;
    /* opacity: 0.7; */
    /* Adjust the opacity as needed (0.0 to 1.0) */
    /* filter: blur(4px); */
    /* Adjust the blur radius as needed */
}
/* Style the chat heading */
.chat-heading {
    font-size: 32px;
    color: #333;
    margin-bottom: 20px;
}
/* Style the chat messages container */
.chat-messages-container {
    max-height: 800px;
    overflow-y: auto;
    padding: 20px;
}
.chat-messages-container::-webkit-scrollbar {
    width: 6px;
    /* Width of the scrollbar thumb */
}
.chat-messages-container::-webkit-scrollbar-thumb {
    background-color: rgba(33, 31, 95, 0.059);
    /* Color of the scrollbar thumb */
    border-radius: 3px;
    /* Rounded corners for the thumb */
}
/* Style chat messages */
/* .message {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
    padding: 15px;
    border-radius: 15px;
    font-size: 16px;
    max-width: 95%;
} */
.message {
    /* display: flex;
    justify-content: flex-end; */
    padding: 10px;
    border-radius: 15px;
    font-size: 16px;
    max-width: 98%;
    word-wrap: break-word;
    background-color: #f5f5f5;
    margin-bottom: 10px;
}
/* .sent {
    background-color: #007bff;
    color: #fff;
    text-align: left;
    align-self: flex-start  ;
} */
/* .sent {
    background-color: #007bff;
    color: #fff;
    text-align: left;
    display: inline-block;
    justify-content: flex-end;
    align-content: end;
  } */
/* .message.sent {
    background-color: #007bff;
    color: #fff;
    text-align: left;
    align-self: flex-end;
    max-width: 60%;
    display: inline-block;
    margin-left: auto; 
    
} */
.message.sent {
    background-color: #007bff;
    color: #fff;
    text-align: left;
    align-self: flex-end;
    /* display: inline-block;  */
    margin-left: auto;
    max-width: 60%;
    text-wrap: balance;
    /* max-width: 600px;
    word-wrap: break-word; */
    /* float: right;  */
}
.message.received {
    background-color: #007bff;
    color: #fff;
    text-align: left;
    align-self: flex-end;
    max-width: 60%;
    text-wrap: balance; 
    /* max-width: 600px;
    word-wrap: break-word; */
    /* display: inline-block; */
}
/* .received {
    background-color: #f5f5f5;
    color: #333;
    align-self: flex-end;
} */
/* .received {
    align-self: flex-start;
  } */
.sender {
    font-weight: bold;
    margin-right: 5px;
}
/* Style the message content */
.message-content {
    flex-grow: 1;
}
/* Style the sent time */
.sent-time {
    color: #ede9e9;
    font-size: 12px;
}
/* Style the message input area */
.message-input {
    margin-top: 20px;
    display: flex;
    align-items: center;
}
.message-input textarea {
    flex-grow: 1;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none;
    font-size: 16px;
}
.message-input button {
    
    padding: 15px 30px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.message-input button:hover {
    background-color: #0056b3;
}
/* Add this CSS */
.message-metadata {
    color: #333333;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 1px;
    margin-left: 5px;
    /* margin-right: 55px; */
    display: flex;
    justify-content: flex-end;
}
.avatar {
    width: 32px;
    /* Adjust the size as needed */
    height: 32px;
    background-color: #c0da59;
    /* Background color of the circle */
    color: #fff;
    /* Text color inside the circle */
    border-radius: 70%;
    /* Make it a circle */
    text-align: center;
    line-height: 32px;
    /* Vertically center the text */
    font-weight: bold;
    margin-right: 10px;
    /* Add spacing between the circle and the message */
    /* display: flex; */
    float: left;
}
.avatar2 {
    width: 32px;
    /* Adjust the size as needed */
    height: 32px;
    background-color: #da59a4;
    /* Background color of the circle */
    color: #fff;
    /* Text color inside the circle */
    border-radius: 70%;
    /* Make it a circle */
    text-align: center;
    line-height: 32px;
    /* Vertically center the text */
    font-weight: bold;
    margin-right: 10px;
    /* Add spacing between the circle and the message */
    /* display: flex; */
    float: left;
}
.pre_text_css {
overflow: hidden;
text-wrap: balance;
}
.div-download-btn{
    display: flex;
    justify-content: center;
    width:60%;
}

.download-btn{
    background: transparent;
    border: none;
    color: white;
    position: relative;
  
}

.preview-div{
    width: 28%;
    height: 25%;
    border-radius: 5px;
    padding: 10px;
    margin-left: 22px;
    text-wrap: balance;
}