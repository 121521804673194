.custom-mdb-table {
    border-collapse: collapse;
  }
  
  .custom-mdb-table th,
  .custom-mdb-table td {
    border: 2px solid black;
  }

  .custom-mdb-table th:nth-child(1),
.custom-mdb-table td:nth-child(1) {
  width: 100px; /* Set the width of the first column to 150px */
}

.custom-mdb-table th:nth-child(2),
.custom-mdb-table td:nth-child(2) {
  width: 100px; /* Set the width of the second column to 200px */
}

.custom-mdb-table th:nth-child(10),
.custom-mdb-table td:nth-child(10) {
  width: 350px; /* Set the width of the second column to 200px */
}

.custom-mdb-table td {
  vertical-align: middle;
}

.table-container {
  margin: 0 auto 0 50px;  /* Set horizontal margin to auto to center the table */
}

.custom-mdb-table th {
  text-align: center;
  vertical-align: middle;
}

.dataTables_length {
  margin-bottom: 20px; /* Set the margin bottom here */
}

.show-entries-label .dataTables_length > label {
  margin-left: 50px; /* Set the left margin to add space on the left of the label */
}

